/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from "../../lib/api";
export const getProjectListData = async (queryParams) => {
    const { data } = await api.post(`/projects/search${queryParams && "?" + queryParams}`);
    return data; // return the data if the API call succeeds
};
export const getProjectAssetsData = async (id, queryParams) => {
    const { data } = await api.post(`/projects/${id}/assets/search${queryParams && "?" + queryParams}`);
    return data;
};
export const getUsageHistoryDetailsData = async (id, startDate, aggregation, assetSourceType, assetId) => {
    const config = { params: { aggregation, assetSourceType, startDate, ...(assetId && { assetId }) } };
    const { data } = await api.get(`/projects/${id}/energy/histogram`, config);
    return data;
};
export const getProjectEnergyMetricsData = async (id, startDate) => {
    const config = { params: { startDate } };
    const { data } = await api.get(`/projects/${id}/energy/metrics`, config);
    return data;
};
export const getProjectNetworkData = async (id) => {
    const { data } = await api.get(`/projects/${id}/network/connections`);
    return data;
};
export const getProjectEnergyActivitiesData = async (id, startDate) => {
    const config = { params: { startDate } };
    const { data } = await api.get(`/projects/${id}/energy/activities`, config);
    return data;
};
export const getProjectAssetChargeActivitiesData = async (id, startDate) => {
    const { data } = await api.post(`/projects/${id}/assets/events/search?startDate=${startDate}`);
    return data;
};
export const getProjectAssetChargeActivitiesDataWithAssetId = async (id, startDate, assetId) => {
    const requestParams = {
        filters: [
            {
                propertyName: "assetId",
                type: "stringEquals",
                values: [assetId]
            }
        ]
    };
    const { data } = await api.post(`/projects/${id}/assets/events/search?startDate=${startDate}`, requestParams);
    return data;
};
